@import "../../../assets/sass/helpers/_breakpoints.scss";
@import "../../../assets/sass/helpers/_variables.scss";
.horizontal-booking-form {
    background: $secondary-color;
    margin-bottom: 30px;
    padding: 20px 30px 20px 30px;
    @include bp(lgx) {
        padding: 20px 40px 20px 40px;
    }
    @include bp(mdx) {}
    @include bp(smx) {}
    @include bp(xsx) {}
    @include bp(xxsx) {}
    .arrival-departure {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        align-items: start;
        padding-bottom: 0;
        padding: 15px;
        padding-top: 10px;
        @include bp(xlx) {
            padding-top: 0;
            flex-direction: column;
            justify-content: start;
            padding-bottom: 0;
        }
        @include bp(lgx) {
            padding-top: 10px;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 0;
        }
        @include bp(mdx) {
            padding-top: 0;
            flex-direction: column !important;
            justify-content: start;
            padding-bottom: 0;
        }
        .form-outer {
            width: calc(50% - 12px) !important;
            @include bp(mdx) {
                width: 100% !important;
                margin-bottom: 20px;
            }
            >label {
                height: 30px;
                color: $white-color;
                text-align: left;
                position: relative;
                width: 100%;
            }
        }
        .form-outer:nth-of-type(2) {
            margin-left: 24px;
            @include bp(mdx) {
                margin-left: 0;
            }
        }
    }
    .form-rooms {
        background: rgba(60, 72, 111, 0.3);
        padding: 0 20px;
        @include bp(lgx) {
            padding-bottom: 10px;
        }
        &-room {
            padding: 5px 0 7px 0;
            display: 'flex';
            flex-direction: 'column';
            justify-content: 'space-between';
            align-items: 'start';
            span {
                display: block;
                width: 100%;
                text-align: left;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                font-size: 1.2em;
                color: #ffffff;
                svg {
                    float: right;
                    color: $primary-color;
                }
            }
            &-top {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 10px;
                @include bp(xxsx) {
                    flex-direction: column;
                }
                .form-outer {
                    width: calc(50% - 12px);
                    @include bp(xxsx) {
                        width: 100%;
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    .form-adults,
                    .form-childs {
                        width: 100%;
                    }
                }
            }
        }
        &>div {
            border-top: 1px solid rgba(255, 255, 255, 0.2);
        }
        &>div:nth-of-type(1) {
            border-top: none;
        }
        .children-age {
            i {
                width: 50%;
                margin-left: calc(50% + 12px);
                padding-right: 15px;
                display: block;
                margin-top: 6px;
                color: #fff;
                font-style: normal;
                text-align: right;
                @include bp(mdx) {
                    margin-left: 0;
                    padding-right: 0;
                }
                @include bp(lgx) {
                    text-align: left;
                }
                @include bp(mdx) {
                    width: 100%;
                    text-align: right;
                }
            }
            .child-age-select {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @include bp(lgx) {
                    justify-content: flex-start;
                }
                select,
                label {
                    width: calc(50% - 12px);
                    display: flex;
                    align-items: center;
                    height: 36px;
                }
                label {
                    justify-content: end;
                    color: #fff;
                }
                select {
                    padding: 0 10px 0 5px !important;
                    @include bp(lgx) {
                        width: 25%;
                        margin-left: 24px;
                    }
                    @include bp(mdx) {
                        width: calc(50% - 12px);
                    }
                }
            }
        }
        .add-room {
            float: right;
            height: 34px;
            align-items: center;
            justify-content: right;
            @include bp(lgx) {
                float: none;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .delete-room {
            float: right;
            color: $primary-color;
            margin-right: 20px;
            @include bp(lgx) {
                float: none;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    .booknow-container {
        display: flex;
        align-self: start;
        justify-content: center;
        padding-bottom: 1rem;
        padding: 0;
        margin-top: 41px;
        .book-now-button {
            background: $primary-color;
            color: $light-color;
            font-weight: bold;
            padding: 10px 18px;
            width: calc(100% - 20px);
            text-transform: uppercase;
        }
    }
}

@media screen and (max-width: 991px) {
    .horizontal-booking-form {
        .booknow-container {
            .book-now-button {
                width: auto;
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .horizontal-booking-form {
        .arrival-departure {
            padding-top: 10px !important;
        }
    }
}