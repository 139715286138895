    @import "../../../assets/sass/helpers/_breakpoints.scss";
    @import "../../../assets/sass/helpers/_variables.scss";
    .booking-form-wrap {
        padding-top: 30px;
        .booking-button {
            button {
                text-transform: uppercase;
            }
        }
        h3 {
            margin-top: 5px;
            color: $white-color;
            @include bp(lgx) {
                display: flex;
                justify-content: center;
            }
        }
        .booking-form {
            .booking-form-section {
                @include bp(mdx) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 25px;
                }
                .submit-btn {
                    input {
                        text-transform: uppercase;
                    }
                }
            }
            &>div:nth-child(2) {
                @include bp(lgx) {
                    margin-right: 0;
                }
            }
            &>div:nth-child(3) {
                @include bp(lgx) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
            .form-group {
                input::placeholder {
                    color: #383838;
                }
                >label {
                    color: $white-color;
                    text-align: left;
                    position: relative;
                    width: 100%;
                }
            }
        }
    }
    
    .booking-form-short {
        .form-adults-childredn {
            font-size: 0.875em;
            padding: 0 15px;
            width: 100%;
            select {
                padding: 0 10px 0 8px;
            }
            .form-rooms {
                background: rgba(60, 73, 111, 0.3);
                padding: 0 20px;
                margin-top: 10px;
                @include bp(lgx) {
                    padding-bottom: 10px;
                }
                &-room {
                    padding: 5px 0 7px 0;
                    display: 'flex';
                    flex-direction: 'column';
                    justify-content: 'space-between';
                    align-items: 'start';
                    span {
                        display: block;
                        width: 100%;
                        text-align: left;
                        height: 34px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-weight: bold;
                        font-size: 1.2em;
                        color: #ffffff;
                        svg {
                            float: right;
                            color: $primary-color;
                        }
                    }
                    &-top {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        @include bp(xxsx) {
                            flex-direction: column;
                        }
                        .form-outer {
                            width: calc(50% - 12px);
                            @include bp(xxsx) {
                                width: 100%;
                                margin-bottom: 10px;
                                margin-top: 10px;
                            }
                            .form-adults,
                            .form-childs {
                                width: 100%;
                            }
                        }
                    }
                }
                &>div {
                    border-top: 1px solid rgba(255, 255, 255, 0.2);
                }
                &>div:nth-of-type(1) {
                    border-top: none;
                }
                .children-age {
                    i {
                        width: 50%;
                        margin-left: calc(50% + 12px);
                        padding-right: 15px;
                        display: block;
                        margin-top: 6px;
                        color: #fff;
                        font-style: normal;
                        text-align: right;
                        @include bp(mdx) {
                            margin-left: 0;
                            padding-right: 0;
                        }
                        @include bp(lgx) {
                            text-align: left;
                        }
                        @include bp(mdx) {
                            width: 100%;
                            text-align: right;
                        }
                    }
                    .child-age-select {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        @include bp(lgx) {
                            justify-content: flex-start;
                        }
                        select,
                        label {
                            width: calc(50% - 12px);
                            display: flex;
                            align-items: center;
                            height: 36px;
                        }
                        label {
                            justify-content: end;
                            color: #fff;
                        }
                        select {
                            padding: 0 10px 0 5px !important;
                            @include bp(lgx) {
                                margin-left: 24px;
                            }
                            @include bp(mdx) {
                                width: calc(50% - 12px);
                            }
                        }
                    }
                }
                .add-room {
                    float: right;
                    height: 34px;
                    align-items: center;
                    justify-content: right;
                    @include bp(lgx) {
                        float: none;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
                .delete-room {
                    float: right;
                    color: $primary-color;
                    margin-right: 20px;
                    @include bp(lgx) {
                        float: none;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
    
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        border-radius: 0em !important;
        background-color: $primary-color !important;
    }
    
    .react-datepicker__day--selected:hover {
        border-radius: 0em !important;
        background-color: $primary-color !important;
    }
    
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        border-radius: 0em !important;
        background-color: $primary-color !important;
    }
    
    .react-datepicker {
        border-radius: 0rem !important;
    }
    
    .booking-transfer {
        position: relative;
        width: 100%;
        .booking-content {
            p.transfer-text {
                color: $white-color;
                font-weight: 300;
                margin-top: 0;
                margin-bottom: 30px;
            }
        }
        .flight-details {
            >div {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .form-group {
            .react-datepicker-wrapper {
                margin-bottom: 10px;
                @include bp(smx) {
                    margin-bottom: 10px;
                }
            }
            label {
                color: white;
                width: 100%;
                font-size: 1em;
            }
            label.label-fd {
                margin-top: 5px;
            }
        }
        .flight-details {
            >div:nth-of-type(1) {
                margin-bottom: 20px;
            }
        }
        .transfer-villages {
            >div:nth-of-type(1) {
                @include bp(smx) {
                    margin-bottom: 10px;
                }
            }
        }
        .book-deal-text {}
    }