.site-footer {
    .about-p {
        p {
            display: flex;
            align-items: center;
            img {
                width: 38px;
                height: 24px;
            }
            span {
                width: calc(100% -38px);
                padding-left: 10px;
            }
        }
    }
}