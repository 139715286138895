    @import "../../../assets/sass/helpers/_breakpoints.scss";
    @import "../../../assets/sass/helpers/_variables.scss";
    .menuouter {
        display: flex;
        @include bp(lgx) {
            flex-direction: column;
        }
        .menu {
            @include bp(lgx) {
                order: 3;
                margin-top: 15px;
                border-top: 1px solid rgba(0, 0, 0, 0.25);
            }
        }
        .header-btn {
            text-transform: uppercase;
            @include bp(lgx) {
                order: 2;
                margin-top: 10px;
            }
        }
    }
    
    .bottom-header {
        @include bp(lgx) {
            padding: 0;
        }
        .menuouter.container {
            @include bp(lgx) {
                max-width: 100%;
                padding: 0;
            }
        }
        .site-identity {
            h1 {
                display: none;
                @include bp(lgx) {
                    display: block;
                    margin-top: 10px;
                }
                a {
                    font-size: 48px;
                    font-weight: 900;
                    &:hover {
                        color: $title-color;
                        text-decoration: none;
                    }
                }
            }
            h2 {
                @include bp(lgx) {
                    display: none;
                }
            }
        }
    }
    
    main {
        section.home-banner-none {
            display: none;
        }
        .slider-button {
            a {
                text-transform: uppercase;
            }
        }
    }
    
    .header-primary {
        .bottom-header {
            .menuouter {
                .site-identity {
                    @include bp(lgx) {
                        display: none;
                    }
                }
                .header-btn {
                    text-transform: uppercase;
                    @include bp(lgx) {
                        display: none;
                    }
                }
                .menuwhite {
                    margin-top: 0;
                    border-top: none;
                    button.mobile-menu-icons {
                        color: $white-color !important;
                    }
                }
            }
        }
    }
    
    .site-header {
        .header-btn {
            background-color: $primary-color;
            padding: 10px 18px;
            a {
                color: $light-color;
                text-decoration: none;
                font-weight: bold;
            }
        }
        .header-btn:hover {
            background-color: $hover-color;
        }
        .top-header {
            position: relative;
            height: 45px;
            .justify-content-end {
                position: relative;
                z-index: 100;
            }
            .header-contact-info {
                ul {
                    li.company-email {
                        @include bp(xsx) {
                            display: none;
                        }
                    }
                }
            }
            #languages {
                position: relative;
                z-index: 10050;
            }
        }
    }
    
    .banner-content-wrap {
        .col-lg-4 {
            .booking-form-wrap {
                form {
                    align-items: center;
                }
            }
        }
    }
    
    .banner-content-wrap {
        .booking-form-wrap {
            @include bp(lgx) {
                margin-left: 10%;
                margin-right: 10%;
            }
            @include bp(smx) {
                margin-left: 5%;
                margin-right: 5%;
            }
        }
    }