.custominput {
    height: 46px;
    display: flex;
    cursor: hand;
    cursor: pointer;
    justify-content: space-evenly;
    box-sizing: border-box;
    align-items: stretch;
    label {
        cursor: hand;
        cursor: pointer;
        position: relative;
        flex-shrink: 0;
        flex-grow: 1;
        padding: 0 10px;
        height: 100%;
        line-height: 46px;
        align-self: left;
        flex-basis: calc(100% - 55px);
        text-align: left;
    }
    .icon {
        cursor: hand;
        cursor: pointer;
        text-align: right;
        flex-shrink: 0;
        flex-grow: 1;
        line-height: 46px;
        flex-basis: 55px;
        height: 100%;
        svg {
            margin-right: 15px;
        }
    }
}