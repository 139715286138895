@import "../../../assets/sass/helpers/_breakpoints.scss";
@import "../../../assets/sass/helpers/_variables.scss";
.menu {
    position: relative;
    width: 100%;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 10px 0 15px 0;
        li {
            color: $white-color;
            a {
                color: $white-color;
                display: inline-block;
                font-size: 15px;
                padding: 10px 0;
                font-weight: 600;
                line-height: 1.3;
                position: relative;
                text-transform: uppercase;
            }
            a:hover {
                color: $primary-color;
                text-decoration: none;
            }
            ul {
                li {
                    a {
                        color: $title-color;
                    }
                }
            }
        }
    }
    button {
        position: relative;
        width: 100%;
        background: transparent;
        color: $secondary-color !important;
        margin: 0;
        padding: 0;
        border: none;
        svg {
            margin-top: -5px;
        }
        span {
            margin-left: 15px;
            line-height: 60px;
        }
    }
    button:hover,
    button:focus,
    button:active {
        border: none;
        outline: 0px;
    }
    ul.nav-links {
        @include bp(lgx) {
            display: none;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        li {
            padding: 0 8px;
        }
    }
    ul.nav-links-mobile {
        @include bp(lgx) {
            background: $white-color;
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            padding-top: 10px;
        }
        li {
            a {
                color: $secondary-color;
                padding: 15px 0;
            }
        }
    }
    .mobile-menu-icons {
        display: none;
        @include bp(lgx) {
            display: block;
            position: relative;
            z-index: 40;
        }
    }
}

.menudark {
    ul {
        li {
            a {
                color: $secondary-color;
            }
        }
    }
}

.menuwhite {
    ul.sub__menus {
        a {
            color: $title-color;
        }
    }
}

@media screen and (min-width: 991px) and (max-width: 992px) {
    ul.nav-links {
        li {
            height: 100px;
            display: flex;
            align-items: center;
            a {
                padding: 0;
                height: 22px;
                overflow: hidden;
            }
        }
    }
}

.nav-links-mobile {
    border: 1px solid blue;
}