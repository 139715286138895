@import "../../../assets/sass/helpers/_breakpoints.scss";
@import "../../../assets/sass/helpers/_variables.scss";
.home-banner-section {
    .section-heading-white {
        margin-top: -30px;
        font-size: 1em;
        @include bp(lgx) {
            margin-top: 0;
            font-size: 0.8em;
        }
        @include bp(mdx) {
            font-size: 0.5em;
        }
        h1 {
            text-transform: uppercase;
            font-size: 4.375em !important;
            color: $white-color;
            line-height: 1.1;
            font-weight: 900;
            @include bp(lgx) {
                margin-top: 0;
            }
            b {
                float: left;
                display: inline-block;
                width: 100%;
            }
            span {
                float: left;
                display: inline-block;
                font-size: 0.7em;
                text-transform: capitalize;
                width: 100%;
                margin-top: 0.3em;
                margin-bottom: 0.5em;
                color: rgba(255, 255, 255, 0.7);
                font-weight: 600;
            }
        }
        h5 {
            font-size: 1.1em;
            @include bp(mdx) {
                font-size: 1.8em;
                margin-bottom: 8px;
            }
            @include bp(smx) {
                display: none;
            }
        }
    }
    .search-form-home {
        .booking-form-wrap {
            @include bp(md) {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
    .booking-form-short {
        margin-top: 0;
        @include bp(lgx) {
            margin-top: 30px;
        }
    }
    .section-heading {
        margin-bottom: 0 !important;
        @include bp(md) {
            text-align: left;
        }
    }
    .banner-content-wrap {
        padding-top: 200px;
        padding-bottom: 60px;
        @include bp(lgx) {
            padding-top: 130px;
        }
        @include bp(smx) {
            padding-top: 100px;
        }
    }
}