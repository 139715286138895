@import "../../../assets/sass/helpers/_breakpoints.scss";
@import "../../../assets/sass/helpers/_variables.scss";
.languadge-dropdown {
    width: 154px;
    .dropdown-header {
        padding: 12px 0 11px 0;
        cursor: pointer;
        display: flex;
        justify-content: end;
        align-items: center;
        padding-right: 10px;
        span {
            width: auto;
            text-align: center;
            font-size: 15px;
            color: #212529;
            padding-left: 8px;
            text-align: left;
        }
        img {
            width: 24px;
            height: 14px;
            padding-right: 3px;
        }
    }
    .dropdown-body {
        background-color: white;
        display: none;
        width: 100%;
        border: 1px solid $disable-color;
        border-top: none;
    }
    .dropdown-body.open {
        display: block;
    }
    .dropdown-item {
        display: flex;
        padding: 10px;
        align-items: center;
        img {
            border: 1px solid $disable-color;
            height: 14px;
            width: 20px !important;
        }
        span {
            width: calc(100% - 30px);
            margin-left: 10px;
            text-align: left;
        }
    }
    .dropdown-item:hover {
        cursor: pointer;
    }
    .dropdown-item-dot.selected {
        opacity: 1;
    }
    .icon {
        font-size: 13px;
        color: $secondary-color-light;
        transform: rotate(0deg);
        transition: all .2s ease-in-out;
        margin-left: 10px;
    }
    .icon.open {
        transform: rotate(90deg);
    }
}

.header-primary {
    .languadge-dropdown {
        .dropdown-header {
            span {
                color: $light-color;
            }
        }
        .icon {
            color: $light-color;
        }
    }
}